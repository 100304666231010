export enum EPlanetSizes {
  mercury = "290px",
  venus = "400px",
  earth = "450px",
  mars = "336px",
  jupiter = "582px",
  saturn = "665px",
  uranus = "458px",
  neptune = "455px",
}
